import * as Yup from "yup";
import { FieldErrors } from "react-hook-form";

import { PaymentMethod } from "Constants";
import { TranslateFunc, TranslateWithArgumentFunc, UserAccount } from "Providers";
import { orgNoValidation, phoneNumberValidation } from "Utils";
import { validateLuhn } from "TempUtils";
import { OnlineCheckoutFormValues } from "./OnlineCheckout";
import { OnlinePaymentMethod, UserAccountContactInformation } from "Types";

export const getOnlineCheckoutValidation = (
    isDisableTableService: boolean,
    isDelivery: boolean,
    hasMandatoryComment: boolean,
    showTableField: boolean,
    translate: TranslateFunc,
    translateWithArgument: TranslateWithArgumentFunc
) => {
    const homeDeliveryValidationSchema = Yup.object().shape({
        addressLine: Yup.string().required(translate("requiredError")),
        city: Yup.string().required(translate("requiredError")),
        lastName: Yup.string().required(translate("requiredError"))
    });

    const onlineCheckoutValidationSchema = Yup.object().shape({
        name: Yup.string().required(translate("requiredError")),
        email: Yup.string().trim().required(translate("requiredError")).email(translate("formErrorValidEmail")),
        phoneNumber: Yup.string().required(translate("requiredError")),
        acceptTerms: Yup.boolean().oneOf([true], translate("formErrorMustBeChecked")),
        // NOTE: This is a bit strange, because we validate the paymentMethods, but we don't show the error in the console or in the UI if the paymentMethod is not valid.
        paymentMethod: Yup.string().oneOf(
            [PaymentMethod.SWISH, PaymentMethod.INVOICE, PaymentMethod.CARD, OnlinePaymentMethod.KLARNA, OnlinePaymentMethod.NO_CHARGE],
            translate("requiredError")
        ),
        ...(hasMandatoryComment && { mandatoryComment: Yup.string().required(translate("requiredError")) }),
        tip: Yup.number().positive().min(0, translateWithArgument("minimumAmount", 0)),
        invoiceData: Yup.object().when("paymentMethod", {
            is: val => val === PaymentMethod.INVOICE,
            then: Yup.object().shape({
                organisationNumber: orgNoValidation("").test(
                    "organisationNumber",
                    translate("formErrorOrgNo"),
                    async value => {
                        if (!!value && value.length > 9) {
                            return validateLuhn(value);
                        }
                        return true;
                    }
                ),
                invoiceAddress: Yup.object().shape({
                    shouldUseSameInvoiceAddress: Yup.boolean().oneOf([true, false]),
                    name: Yup.string().required(translate("requiredError")),
                    addressLine: Yup.string().when("shouldUseSameInvoiceAddress", {
                        is: val => !val,
                        then: Yup.string().required(translate("requiredError"))
                    }),
                    postCode: Yup.number().when("shouldUseSameInvoiceAddress", {
                        is: val => !val,
                        then: Yup.number()
                            .typeError(translate("formErrorOnlyNumbers"))
                            .required(translate("requiredError"))
                    }),
                    city: Yup.string().when("shouldUseSameInvoiceAddress", {
                        is: val => !val,
                        then: Yup.string().required(translate("requiredError"))
                    })
                })
            })
        })
    });

    const tableValidationSchema = Yup.object().shape({
        table: Yup.object().shape({
            name: Yup.string().required(translate("requiredError"))
        })
    });
    const disableTableService = Yup.object().shape({
        disableTableService: Yup.boolean().oneOf([true], translate("formErrorMustBeChecked"))
    });
    if (isDisableTableService) {
        return onlineCheckoutValidationSchema.concat(disableTableService);
    }
    if (isDelivery) {
        return onlineCheckoutValidationSchema.concat(homeDeliveryValidationSchema);
    } else {
        if (showTableField) {
            return onlineCheckoutValidationSchema.concat(tableValidationSchema);
        }
        return onlineCheckoutValidationSchema;
    }
};

export const validateRequiredYupFieldsForUserAccount = (
    userAccount: UserAccount | null,
    requiredFields: Record<string, boolean>
): boolean => {
    if (!userAccount) {
        return false;
    }

    const { contactInformation } = userAccount;

    return Object.entries(requiredFields).every(([key, isRequired]) => {
        if (isRequired) {
            const value = contactInformation[key as keyof UserAccountContactInformation];
            return !!value;
        }
        return true;
    });
};

export const shouldDisableSubmitButton = (
    errors: FieldErrors<OnlineCheckoutFormValues>,
    paymentMethod: OnlinePaymentMethod | null
): boolean => {
    const isPaymentMethodInvoice = paymentMethod === OnlinePaymentMethod.INVOICE;

    const excludedFields = isPaymentMethodInvoice ? ["invoiceData", "discount"] : ["discount"];

    return Object.keys(errors).some(key => !excludedFields.includes(key));
};
