import React from "react";

import { Box, ButtonProps, Image, Stack, StackProps, Text } from "Atoms";
import { OnlinePaymentMethod } from "Types";
import { OnlineCheckoutGenericPaymentButton } from "./OnlineCheckoutGenericPaymentButton";
import { useLanguage } from "LanguageProvider";

export const OnlineCheckoutKlarnaPaymentContent: React.FC<StackProps> = props => {
    const { translate } = useLanguage();
    return (
        <Stack stretch={2} isInline align="center" {...props}>
            <Box my={1}>
                <Stack stretch={2} isInline align="center" onClick={() => {}}>
                    <Text as="span">{translate("continueWithKlarna")}</Text>
                    <Image
                        src={"https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"}
                        h={"auto"}
                        w={12}
                    />
                </Stack>
            </Box>
        </Stack>
    );
};

type Props = {
    onClickPay: (paymentMethod: OnlinePaymentMethod) => void;
} & ButtonProps;

export const OnlineCheckoutKlarnaPaymentButton = ({ onClickPay, ...rest }: Props) => (
    <OnlineCheckoutGenericPaymentButton onClick={() => onClickPay(OnlinePaymentMethod.KLARNA)} {...rest}>
        <OnlineCheckoutKlarnaPaymentContent />
    </OnlineCheckoutGenericPaymentButton>
);
