import React from "react";

import { Button, Flex, FlexProps } from "Atoms";
import {
    OnlineCheckoutCardPaymentContent,
    OnlineCheckoutSwishPaymentContent,
    OnlineCheckoutInvoicePaymentContent
} from "./OnlineCheckoutPaymentMethods";
import { useLanguage } from "LanguageProvider";
import { OnlinePaymentMethod } from "Types";
import { OnlineCheckoutKlarnaPaymentContent } from "./OnlineCheckoutPaymentMethods/components/OnlineCheckoutKlarnaPaymentButton";

type Props = {
    onRemovePaymentMethod: () => void;
    selectedPaymentMethod: OnlinePaymentMethod | null;
} & FlexProps;

export const OnlineCheckoutPreferredPaymentMethod: React.FC<Props> = ({
    onRemovePaymentMethod,
    selectedPaymentMethod,
    ...rest
}) => {
    const { translate } = useLanguage();

    // If there is no selected payment method, do not render anything
    if (!selectedPaymentMethod || selectedPaymentMethod === OnlinePaymentMethod.NO_CHARGE) {
        return null;
    }

    // Map the payment method types to their corresponding components
    const paymentMethodComponentMap = {
        SWISH: OnlineCheckoutSwishPaymentContent,
        CARD: OnlineCheckoutCardPaymentContent,
        INVOICE: OnlineCheckoutInvoicePaymentContent,
        KLARNA: OnlineCheckoutKlarnaPaymentContent
    };

    // Get the component for the currently selected payment method
    const SelectedPaymentMethodComponent = paymentMethodComponentMap[selectedPaymentMethod];

    return (
        <Flex h="auto" justify="space-between" align="center" {...rest}>
            <SelectedPaymentMethodComponent fontSize="lg" fontWeight="bold" />
            <Button
                variant="link"
                size="lg"
                themeColor="blue"
                type="submit"
                _focus={{
                    boxShadow: "outline"
                }}
                onClick={onRemovePaymentMethod}
            >
                {translate("change")}
            </Button>
        </Flex>
    );
};
