import React from "react";
import Truncate from "react-truncate";
import { useMedia } from "react-media";

import { useLanguage } from "Providers";
import { GiftCard } from "Types";
import { Flex, NewButton, Image, Box, Header, Text } from "Atoms";
import { OnlineOrderMiniCardRibbon } from "./components";

export type GiftMiniProps = {
    onGiftCardClick: () => void;
    isDisabled?: boolean;
    giftCardSettings: GiftCard | null;
    shopImageUrl: string;
};

const pseudoStyles = (isDisabled: boolean) => {
    return {
        transform: isDisabled ? "" : ["", "", "", "scale(1.01)"],
        boxShadow: isDisabled
            ? ""
            : ["", "", "", "0 8px 12px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"]
    };
};

const getDefaultValues = (isDisabled: boolean) => {
    return {
        border: "none",
        padding: "0",
        height: "100px",
        maxHeight: "100px",
        fontWeight: "400",
        borderRadius: "0.75rem",
        width: "100%",
        backgroundColor: "base",
        borderColor: "blue.200",
        borderStyle: "solid",
        borderWidth: "0.2rem",
        cursor: isDisabled ? "not-allowed" : "pointer",
        paddingLeft: "-1rem",
        overflow: "hidden",
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
        transition: "all 0.2s cubic-bezier(0.44, 2.1, 0.04, 1.65)",
        _hover: { ...pseudoStyles(isDisabled) },
        _active: { ...pseudoStyles(isDisabled) }
    };
};

export const OnlineOrderGiftCardMini: React.FC<GiftMiniProps> = ({
    onGiftCardClick,
    isDisabled,
    giftCardSettings,
    shopImageUrl
}) => {
    const { translate } = useLanguage();

    if (!giftCardSettings) {
        return <></>;
    }

    const { imageUrl, name, minAmount } = giftCardSettings;
    const hasImage = !!imageUrl;
    const isSmallScreen = useMedia({ query: "(max-width: 991px)" });
    const isLargeScreen = useMedia({ query: "(min-width: 1580px)" });
    const smallSreenPadding = isSmallScreen ? 3 : 0;

    const defaultValues = getDefaultValues(false);

    const description = giftCardSettings.description ? giftCardSettings.description : translate("giftCardDescription");

    return (
        <Flex
            height="auto"
            width={isLargeScreen ? "66%" : "100%"}
            minWidth="45%"
            pb={5}
            pl={smallSreenPadding}
            pr={smallSreenPadding}
        >
            <NewButton
                as="button"
                position="relative"
                whiteSpace="pre-wrap"
                onClick={onGiftCardClick}
                isDisabled={isDisabled}
                {...defaultValues}
            >
                <Flex width="100%" textAlign="left" position="relative">
                    <Flex padding={1}>
                        <Box
                            display="inline-block"
                            position="relative"
                            maxHeight="90px"
                            maxWidth="90px"
                            overflow="hidden"
                            borderRadius="50%"
                            borderColor="gray.200"
                            borderStyle="solid"
                            borderWidth={hasImage ? "0.5rem" : 0}
                        >
                            <Image
                                alt={name}
                                src={hasImage ? imageUrl : shopImageUrl}
                                fallbackSrc={""}
                                width="auto"
                                height="100%"
                                opacity={isDisabled ? 0.5 : 1}
                            />
                        </Box>
                    </Flex>

                    <Flex flex="1">
                        <Flex direction="column" padding={3} width="90%">
                            <Box>
                                <Header size="md" as="h4">
                                    {translate("giftCard")}
                                </Header>
                            </Box>
                            <Text wordBreak="break-word">
                                {/** @ts-ignore */}
                                <Truncate lines={3}>{description}</Truncate>
                            </Text>
                        </Flex>
                    </Flex>
                    <OnlineOrderMiniCardRibbon
                        value={`${translate("from")} ${minAmount} kr`}
                    ></OnlineOrderMiniCardRibbon>
                </Flex>
            </NewButton>
        </Flex>
    );
};
