import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { ADD_KLARNA_WEB_ORDER, CREATE_KLARNA_PAYMENT_SESSION, KlarnaMutations } from "GraphQLMutations/KlarnaMutations";
import { CANCEL_KLARNA_WEB_ORDER } from "GraphQLMutations/OrderMutations";
import { useMothershipMutation } from "Hooks/useMothershipMutation";
import { KlarnaPaymentSession, KlarnaSDKType, WebOrderDTOInput } from "Types";
import { BaseBoxProps, Box } from "Atoms";
import { PaymentMethod, envConstants, frontyEnv } from "Constants";
import { useOnlineStore, useQoplaStore } from "Stores";

const KLARNA_SDK_LIB_URL = "https://x.klarnacdn.net/kp/lib/v1/api.js";

// this should live it's own lifecycle and only update the CheckoutStore
export const useKlarna = () => {
    const [klarnaSDK, setKlarnaSDK] = useState<KlarnaSDKType>();

    const divRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const [addKlarnaWebOrder] = useMothershipMutation<
        KlarnaMutations.AddKlarnaWebOrder,
        { webOrderInput: WebOrderDTOInput }
    >(ADD_KLARNA_WEB_ORDER);

    const [createKlarnaPaymentSession] = useMothershipMutation<
        KlarnaMutations.CreateKlarnaPaymentSession,
        { webOrderInput: WebOrderDTOInput }
    >(CREATE_KLARNA_PAYMENT_SESSION);

    const [cancelKlarnaWebOrder] = useMothershipMutation<{ cancelKlarnaWebOrder: Boolean }, { orderId: String }>(
        CANCEL_KLARNA_WEB_ORDER
    );

    const KlarnaSDKHelmet = () => {
        const onKlarnaLoaded = () => {
            if (window?.Klarna && !klarnaSDK) {
                try {
                    setKlarnaSDK(window.Klarna);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        const handleScriptInject = (newState: any, addedTags: any) => {
            const newScriptTags = addedTags?.scriptTags;
            if (newScriptTags) {
                const foundScript = newScriptTags.find(({ src }: any) => src === KLARNA_SDK_LIB_URL);
                if (foundScript) {
                    /**
                     * The tag had just been added to the Head
                     * We want to execute `onKlarnaLoaded` when the script has finished loading
                     */
                    foundScript.addEventListener("load", onKlarnaLoaded);
                }
            } else {
                const existingScriptTags = newState?.scriptTags;
                const foundScript = existingScriptTags?.find(({ src }: any) => src === KLARNA_SDK_LIB_URL);
                if (foundScript) {
                    /**
                     * The script tag already exists in the Head state and should already be loaded
                     * We can just call `onKlarnaLoaded` now to show the button
                     */
                    onKlarnaLoaded();
                }
            }
        };

        /**
         * This injects the Klarna SDK script into the Head of the document
         * the `onChangeClientState` prop is a callback that is called when the script tag's state changes
         *
         * This includes a state for when the script tag is loaded.  This is monitored by the `handleScriptInject` function.
         * We can only use the Klarna SDK when the script has been loaded.
         *
         * Once the script has been loaded, the `klarnaSDK` state is set to the global `Klarna` object and is ready to be used
         */
        return (
            <Helmet
                script={[{ src: KLARNA_SDK_LIB_URL }]}
                onChangeClientState={(newState: any, addedTags: any) => {
                    handleScriptInject(newState, addedTags);
                }}
            />
        );
    };

    const KlarnaPaymentsContainer = ({ ...rest }: BaseBoxProps) => {
        return <Box my={2} ref={divRef} id="klarna-payments-container" {...rest} />;
    };

    return {
        // To fully launch Klarna, return true here always
        isKlarnaPaymentEnabled: () => {
            const { selectedShop } = useQoplaStore();

            // If the production shop is not on this list, Klarna will be disabled
            const whitelistProductionShopIds = [
                "boland", // Test, not active in production
                "602aea369e7de3034b915238", // Prod Qopla Sticker Shop,
                "6405e8e69a76f60a0b65eaf9", // Prod Svenska Hamburgerköket
                "6405ecfef5c3194708329b76", // Prod Svenska Sushiköket
                // All Benne pasta shops 
                "66ffc23ece2e262dd08ef232", "66ffc4976ad70b169217a599", "66ffc0de84bfc96e073eccd0", 
                "66cf1e9c5ba82356be7bc41b", "66ffb9f5fcf9911ddc6851b2","66ffc2ec63d32018be03c693", 
                "66ffb8c2bb5f9f195e7989ca", "66ffc3d1ce2e262dd08ef350", "66ffc19c8ec02367a0e531d1"
            ];

            if (envConstants.PROFILE === frontyEnv.PROD) {
                return whitelistProductionShopIds.includes(selectedShop?.id || "");
            }

            // Local/Dev/Demo are always enabled
            return true;
        },
        initializeKlarnaSDK: async (paymentSession: KlarnaPaymentSession | undefined) => {
            if (window?.Klarna && paymentSession?.clientToken) {
                try {
                    setKlarnaSDK(window.Klarna);
                    klarnaSDK?.Payments.init({ client_token: paymentSession.clientToken });
                } catch (error) {
                    console.log(error);
                }
            }
        },
        createKlarnaPaymentSession: async (webOrderInput: WebOrderDTOInput) => {
            // Let's nullify/recreate the payment session
            console.log("Initializing Klarna Payment Session");

            const klarnaPaymentSessionResponse = await createKlarnaPaymentSession({
                variables: {
                    webOrderInput
                }
            });
            return klarnaPaymentSessionResponse?.data?.createKlarnaPaymentSession;
        },
        addKlarnaWebOrder: async (
            webOrderInput: WebOrderDTOInput
        ): Promise<KlarnaMutations.AddKlarnaWebOrder | null> => {
            console.log("Adding Klarna Web Order");

            if (!klarnaSDK) {
                console.log("Klarna SDK not found");
                return null;
            }

            const { data } = await addKlarnaWebOrder({
                variables: {
                    webOrderInput
                }
            });
            const order = data?.addKlarnaWebOrder?.order;

            if (data?.addKlarnaWebOrder?.webPaymentResponse?.operationSuccess) {
                console.log("Klarna Web Order added successfully");

                klarnaSDK?.Payments.load(
                    {
                        container: "#klarna-payments-container"
                    },
                    {},
                    res => {
                        console.log(`Klarna Payments load callback: ${JSON.stringify(res)}`);

                        console.log(`Attempting to authorize Klarna Payments `);
                        // TODO: Consider if we want to try to populate billing_address from the UserInformation for logged in users or if this is a later feature
                        klarnaSDK?.Payments.authorize(
                            {},
                            {
                                // billing_address: {
                                // 	"given_name": "Alice",
                                // 	"family_name": "Test",
                                // 	"email": "customer@email.se",
                                // 	// "street_address": "Södra Blasieholmshamnen 2",
                                // 	// "postal_code": "11 148",
                                // 	// "city": "Stockholm",
                                // 	// "phone": "+46701740615",
                                // 	// "country": "SE"
                                // }
                                // "shipping_address": {
                                //   If blank, the billing address will be used as shipping address automatically by Klarna
                                // },
                            },
                            res => {
                                console.log(`Klarna Payments authorize callback: ${JSON.stringify(res)}`);
                                if (!!order?.id) {
                                    if (res.approved) {
                                        navigate(`/order-status/${order.id}`, {
                                            state: {
                                                paymentType: PaymentMethod.KLARNA
                                            }
                                        });
                                    } else {
                                        console.log("Klarna Payment not approved, cancelling order");
                                        cancelKlarnaWebOrder({
                                            variables: { orderId: order?.id }
                                        });
                                    }
                                }
                            }
                        );
                    }
                );
            } else {
                throw new Error("Failed to add Klarna Web Order");
            }

            return data;
        },
        KlarnaSDKHelmet,
        KlarnaPaymentsContainer
    };
};
